import { useContext, useState, useCallback } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { oxfordCommaJoin } from '@utils';
import { getProjects, backfillBrandProjectAccess } from '@/services/api/workspaces.brand';
import { WorkspaceObjectRole } from '@/enums';
import ActivityIndicator from '@/components/ActivityIndicator';
import { Header } from '@/components/Modal';
import { RoleSelector } from '@/components/ObjectAccess';
import { Buttons, Button } from '@/components/Button';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { groupBy } from '@/utils/array';
import { BrandAccessStepperContext, useBrandId } from './Context';
import styles from './styles/Brand.Access.Modal.css';
import type { IUser } from './interfaces';

type ProjectItem = Awaited<ReturnType<typeof getProjects>>['items'][number];

export const AddToProjects = () => {
  const ctx = useContext(BrandAccessStepperContext);

  const brandId = useBrandId();

  const projectsQuery = useQuery({
    queryKey: ['get:brand-projects', brandId],
    queryFn: () => getProjects({ brandId }),
  });

  if (projectsQuery.isInitialLoading)
    return <ActivityIndicator show={true} />;

  if (!projectsQuery.data?.items?.length) {
    ctx.onSuccess();
    return null;
  }

  return <Content projects={projectsQuery.data.items} />;
};

type ContentProps = {
  projects: ProjectItem[];
};

const Content = (props: ContentProps) => {
  const ctx = useContext(BrandAccessStepperContext);
  const [roleState, setRoleState] = useState<Record<number, WorkspaceObjectRole>>(() => buildInitialRoleState(props.projects, ctx.users));
  const brandId = useBrandId();

  const onRoleChange = useCallback((project: ProjectItem, role: WorkspaceObjectRole) => {
    setRoleState(old => ({
      ...old,
      [project.id]: role,
    }));
  }, []);

  const onRemove = useCallback((project: ProjectItem) => {
    setRoleState(old => ({
      ...old,
      [project.id]: undefined,
    }));
  }, []);

  const saveMutation = useMutation({
    mutationFn: () => {
      return backfillBrandProjectAccess({
        brandId,
        users: ctx.users,
        projects: Object.entries(roleState).map(([k, v]) => ({ projectId: +k, roleId: v })),
      });
    },
    onSuccess: result => {
      if (result.upcomingCalls.length) {
        ctx.setProjectMutationResult(result);
        ctx.setUpcomingCalls(result.upcomingCalls);
        ctx.actions.next();
      } else {
        ctx.onSuccess();
      }
    },
  });

  return (
    <div className={styles.main}>
      <Header className={styles.header}>Grant Access To Projects Within This Brand</Header>
      <div className={styles.subHeader}>Want to grant {oxfordCommaJoin(ctx.users.map(u => u.name))} access to projects within this brand? Specify their access levels below to the projects within this brand.</div>
      <div className={styles.projectTable}>
        <div className={styles.tableHeader}>
          <div className={styles.projectCell}>
            Project
          </div>
          <div className={styles.roleCell}>
            Role
          </div>
        </div>
        <div className={styles.tableBody}>
          {props.projects.filter(p => !!roleState[p.id]).map(p => (
            <div key={p.id} className={styles.tableRow}>
              <div className={styles.projectCell}>{p.name}</div>
              <div className={styles.roleCell}>
                <RoleSelector
                  role={roleState[p.id]}
                  roles={VALID_ROLES}
                  onChange={r => onRoleChange(p, r)}
                  onRemove={() => onRemove(p)} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Buttons>
        <Button
          variant='brick'
          color='destructive'
          onClick={ctx.onSuccess}>
          {`Don't Grant Project Access`}
        </Button>
        <ButtonActivityIndicator
          variant='brick'
          color='affirmative'
          implicitDisable={false}
          loading={saveMutation.isLoading}
          onClick={() => saveMutation.mutate()}>
          Grant Access
        </ButtonActivityIndicator>
      </Buttons>
    </div>
  );
};

const VALID_ROLES = [WorkspaceObjectRole.Viewer, WorkspaceObjectRole.Collaborator];

function buildInitialRoleState(projects: ProjectItem[], users: IUser[]): Record<number, WorkspaceObjectRole> {
  const grouped = groupBy(users, k => k.roleId);
  //Choose the most frequent role as the default project role
  const defaultRole = (+Object.entries(grouped).sort((a, b) => b[1].length - a[1].length)[0][0]) as WorkspaceObjectRole;
  const map: Record<number, WorkspaceObjectRole> = {};

  for (const p of projects) {
    map[p.id] = defaultRole;
  }

  return map;
}